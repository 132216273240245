import React from 'react'
import ContactForm from '../components/ContactUs/ContactForm';

const Contact = () => {
    return (
      <>
        < ContactForm />
      </>
  )
}

export default Contact