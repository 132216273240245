import axios from '../api/axios';
import { readFromCache, writeToCache } from './cache';

const getFreshData = async (url, cacheResponse = false) => {
    try {
        const { data } = await axios.get(url);
        cacheResponse && writeToCache(url, data)
        return data;        
    } catch (error) {
      console.log(error)  
    }
};
const postData = async (url, cacheResponse = false, groupPhotos) => {
  try {
      const { data } = await axios.post(url, groupPhotos);  
      cacheResponse && writeToCache(url, data)
        return data;        
    } catch (error) {
      console.log(error)  
    }
};
const getCacheData = (url) => readFromCache(url);

export { getFreshData, postData, getCacheData };