import React, { useState, useEffect } from "react";
import "./Downloads.css";
import { getFreshData } from '../../utils/request';

const FORM_DOWNLOADS = '/api/downloads/get_download_forms';

const Download = ({ useCache }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [forms, setForms] = useState([]);

  const getForms = async () => {
    setForms([]);
    const newFormData = await getFreshData(FORM_DOWNLOADS);
    setForms(newFormData);
  };

  useEffect(() => {
    getForms();
  }, []);


  console.log(forms)
  return (
    <div className="content">
      <h3 className="heading"><i className="fas fa-download"></i> Forms Download</h3>
      <div className="tabs">
        <ul className="nav">
          {forms.map((form, index) => (
            
              <TabNavItem
              key={index}
              title={form.company.replaceAll('_', ' ')}
              id={index}
              activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            
          ))}
        </ul>
        <div className="outlet">
          {forms.map((item, index) => (
            <TabContent id={index} activeTab={activeTab}>
              <History item={item.product} />
            </TabContent>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Download;

function TabNavItem({ id, title, activeTab, setActiveTab }) {
  const handleClick = () => {
    setActiveTab(id);
  };
  return (
    <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
      {title}
    </li>
  );
}

function Product({ id, title, activeTab, setActiveTab }) {
  const handleClick = () => {
    setActiveTab(id);
  };
  return (
    <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
      {title}
    </li>
  );
}

function TabContent({ id, activeTab, children }) {
  return activeTab === id ? <div className="TabContent">{children}</div> : null;
}

function History({ item }) {
  return (
    <>
      <div className="download-1">
        <div className="downloadcard">
          {item.length > 0 ? (
            item.map((itm, i) => (
              <div className="download-card" key={i}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  download={itm.form_name}
                  href={`${process.env.REACT_APP_BASE_URL}/getimage/downloads/${itm.form_path}`}
                >
                  <i
                    className="fa fa-cloud-download"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    &nbsp;&nbsp;Download
                  </i>
                  <h1>{itm.form_name}</h1>
                  <br />
                  <p>{itm.description}</p>
                </a>
              </div>
            ))
          ) : (
           null
          )}
        </div>
        <br />
      </div>
    </>
  );
}
