import React from 'react'
import ImageSlide from '../components/Slide/ImageSlide';
import LogoContent from '../components/Welcome/LogoContent';
import Company from '../components/Welcome/Company';
// import Subsidiaries from '../components/Subsidiaries/Subsidiaries';
import Cards from '../components/Cards/Cards';
import Subsidiary from '../components/Subsidiaries/Subsidiary';


const Home = () => {
    return (
        <>
            <ImageSlide />
            <LogoContent />
            <Company />
            {/* <Cards /> */}
            <Subsidiary />
            {/* <Subsidiaries /> */}
        </>
    )
}

export default Home