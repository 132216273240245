import React from 'react';
import { Link } from 'react-router-dom'
const Pagination = (props) => {

    // page number list based on total number of pages
    const pageNumbers = [];
    const totalPosts = props.data.length;
    for (let i = 1; i <= Math.ceil(totalPosts / props.postPerPage); i++) {
        pageNumbers.push(i)
    }

    const prevPage = () => {
        if (props.currentPage !== 1) {
            props.setCurrentPage(props.currentPage - 1)
        }
    }
    const nextPage = () => {
        if (props.currentPage !== pageNumbers) {
            props.setCurrentPage(props.currentPage + 1)
        }
    }

    return (
            <nav>
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                    <a className="page-link" onClick={prevPage} href="#">Previous</a>
                    </li>
                    {pageNumbers.map(number => (
                        <li key={number} className={props.currentPage === number ? "page-item active" : "page-item"}>
                            <a onClick={() => props.setCurrentPage(number)} className="page-link">{number}</a>
                        </li>
                    ))}
                    <li className="page-item">
                    <a className="page-link" onClick={nextPage} href="#">Next</a>
                    </li>
                </ul>
            </nav>
    )
}

export default Pagination