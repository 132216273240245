/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import BoardChair from "./BoardChair";
import BoardMembers from './BoardMembers';
import { getCacheData, getFreshData, postData } from '../../utils/request';
import './BoardStyles.css';

const BOARD_URL = '/api/management/board';

function BoardInfo({ useCache }) {
    const [board, setBoard] = useState([]);

    const getBoardData = async () => {
       setBoard([]);
       
        if (useCache) {
            const cachedBoardData = getCacheData(BOARD_URL);
            if (cachedBoardData) {
                setBoard(cachedBoardData)
            }
        }
        // const newBoardData = await getFreshData(BOARD_URL, useCache);
        const newBoardData = await postData(BOARD_URL, useCache, {
            company: "NTHC_Ltd",
        });

        setBoard(newBoardData);
    };

    useEffect(() => {
      getBoardData();
    }, [])
        
    return (
        <div className="content">
            <h3 className="heading"> Board of Directors</h3>
            <div className="board">
                {board && board.length > 0 && board.map((boardObj, index) => (
                    <>
                        {boardObj.rank === 1 && (
                            <BoardChair
                                key={index}
                                image={boardObj.image}
                                name={boardObj.name}
                                position={boardObj.position}
                                profile={boardObj.profile}
                            />
                        )}
                    </>
                ))}
                <div className="board-members">
                    {board && board.length > 0 && board.map((boardObj, index) => (
                        <>
                            {boardObj.rank !== 1 && (
                                <BoardMembers
                                    key={index}
                                    image={boardObj.image}
                                    name={boardObj.name}
                                    position={boardObj.position}
                                    profile={boardObj.profile}
                                />
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BoardInfo;