import React, { useEffect } from 'react';
import ReactPortal from '../ReactPortal/ReactPortal';
import './MessageBoxStyles.css';

const MessageBox = ({ children, isOpen, handleClose}) => { 
    
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        }
    }, [handleClose])
       if(!isOpen) return null
    
    return (
        <>
            <ReactPortal wrapperId="react-portal-modal-container">
                <div className="message-box">
                    <div className="message-box-content">
                        {children}
                        <button onClick={ handleClose } className="btn" >Ok</button>
                    </div>
                </div>       
            </ReactPortal>
        </> 
    )
}

export default MessageBox