/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { getCacheData, getFreshData } from '../../utils/request';
import './Research.css'

const NEWSLETTERS_URL = '/api/research/newsletters';

const Newsletters = ( { useCache }) => {
	const [newsletters, setNewsletters] = useState([]);
    
    const getNewsletters = async () => {
       setNewsletters([]);
       
        if (useCache) {
            const cachedNewsletters = getCacheData(NEWSLETTERS_URL);
            if (cachedNewsletters) {
                setNewsletters(cachedNewsletters)
            }
        }
        const newNewsletters = await getFreshData(NEWSLETTERS_URL, useCache);
        setNewsletters(newNewsletters);
    };

    useEffect(() => {
      getNewsletters();
    }, [])
        
  	return (    	
		<div className="content">
			<h3 className="heading">Newsletters</h3><br />
			<p>
			NTHC weekly report summarizing activities on Ghana’s money and capital markets during the specific period.
			</p><br />
			<div className="data-table">
				 <table className="table table-borderless table-sm table-striped table-hover">
					<thead className="align">
						<tr>
							<th scope="col">File</th>
							<th scope="col">Description</th>
							<th scope="col">Edition</th>
							<th scope="col">Date</th>
							<th scope="col">Downloads</th>
						</tr>
					</thead>
						{newsletters.length > 0 ? newsletters.map((item, i) => {	
							return(
								<tbody key={i}>
								<tr  >
									<td style={{ color: 'brown' }}><i className="fa fa-file-pdf"></i></td>
									<td><a rel="noreferrer" target="_blank" href={`/uploads/research/${ item.file_name }`}>{item.description}</a> </td>
									<td>{`Edition ${item.edition}`}</td>
									<td>{item.date}</td>
									<td><a rel="noreferrer" target="_blank" download={"NTHC_Newsletters_" + item.date + ".pdf"} href={`/uploads/research/${ item.file_name }`}><i className="fa fa-cloud-download"></i>&nbsp;&nbsp; Download
									</a></td>
								</tr>
								</tbody>
							)	
						}) : (
								<tbody>
									<tr>
										<td colSpan="5">
									<h3>Newsletters are not available</h3>
										</td>
									</tr>
								</tbody>
							)}
				</table>
			</div>
		</div> 	
  	)
}

export default Newsletters