import React from 'react'
import CompanyData from './CompanyData';
import './CompanyStyles.css';

function Company() {
    return (
        <>
            <div className="company-container">
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src="/images/cowries/cowries_2.jpg" alt="2" />
                            <div className="text-container">
                                <h3>About Us</h3>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <CompanyData
                                heading="About Us"
                                text1="Our foremost pre-occupation is investment banking but, we also have an appetite to explore other value creating opportunities within the business landscape. We endeavour to create strategic business units through which we undertake specific business activities."
                                text2="These strategic business units are incorporated as subsidiary companies to provide diversity of investment held within a respected structure of governance framework."

                                text3="Ultimately it enhances resource optimization and creates relative independence for each business activity while at the same time offering a unique convergence platform for the consummation of the synergies that arises from within."
                            />
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src="/images/cowries/cowries_1.jfif" alt="1" />
                            <div className="text-container">
                                <h3>Our Stakeholders</h3>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <CompanyData
                                heading=" Our Stakeholders"
                                text1="Our dedicated employees, with their diverse skills, expertise, and unwavering passion, have been the driving force behind our remarkable transaction history."
                                
                                text2="As a brand, we have had our unparalleled moments of glory as well as challenging times but in all of these circumstances, we have treasured the value our clients bring to the table, the commitment of our staff and the support of our shareholders."
                            />
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src="/images/cowries/cowries_1.jfif" alt="3" />
                            <div className="text-container">
                                <h3>Businesses</h3>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <CompanyData
                                heading="Business Portfolio"
                              
                                text1="In our present portfolio of businesses are the following:" 
                                text2="     -   Fund Management"
                                text3="     -   Pension Trusteeship"
                                text4="     -   Financial Securities Trading"
                                text5="     -   Corporate Finance Advisory"
                                text6="     -   Equity Share Registry & Company Secretarial"
                                text7="     -   Commodities Trading"
                                text8="     -   Real Estates Development."
                            />
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src="/images/cowries/cowries_3.jpg" alt="3" />
                            <div className="text-container">
                                <h3>Our Pledge</h3>
                            </div>                        </div>
                        <div className="flip-card-back">
                            <CompanyData
                                heading="Our Pledge"
                                text1="By an agile application of our core values, we are certain to remain relevant in the evolving business landscape and entreat our clients to continue trusting in our capabilities."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Company
