import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
// import ChatBottom from '../ChatBottom/ChatBottom'
import Footer from '../Footer/Footer';

const Layout = () => {
  return (
    <>
      <div className='App'>
          <Header />
          <Outlet />
          {/* < ChatBottom />  */}
       </div>
        < Footer />
    </>
  );
};

export default Layout;
