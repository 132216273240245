import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Menu from "./Menu";

const Navbar = ({ toggleDrawer, routes }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);


  return (
    <SNavbar>
      <DrawerButton onClick={toggleDrawer} >
        <i className={toggleDrawer ? "fas fa-bars" : "fas fa-times"} ></i>
      </DrawerButton>

      <NavContainer >
        <RightNav >
          <NavRoutes >
            {routes.map((route) => {
              if (route.subRoutes) {
                return <Menu route={route} key={route.name} />;
              }
              return (
                <NavRoute to={route.link} key={route.name} >
                  {route.name}
                </NavRoute>
              );
            })}
          </NavRoutes>
        </RightNav>
      </NavContainer>

    </SNavbar>
  );
};

export default Navbar;

const DrawerButton = styled.div`
 display: none;
 @media screen and (max-width: 950px){
   display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
`;

const SNavbar = styled.nav`
  background-color: var(--white);
  
`;
const NavContainer = styled.div`
  padding: 1rem;
  height: 80px;
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: var(--main-color);
  
  @media screen and (max-width: 950px) {
    align-items: flex-start;
  }
`;

const RightNav = styled.div`
  display: flex;
  gap: 2rem;
`;

const NavRoutes = styled.div`
 
@media screen and (max-width: 950px) {
    display: none;
  }

  display: flex;
  gap: 1rem;
  font-size: 2rem;
  align-items: center;
`;

// NAVBAR WITHOUT DROPDOWN
const NavRoute = styled(Link)`
  text-decoration: none;
  color: var(--main-color);
  padding: 0.5rem;
  font-size: 20px;
  transition: all 0.5s ease;

  &:hover {
    transition: all 0.2s ease-out;
    color: var(--white);
    background-color: var(--main-color);
    box-shadow: 0px 0px 10px white;
    border-radius: 10px;
  }

 @media screen and (max-width: 1200px) {
     font-size: 1.3rem;
     white-space: nowrap;
  }

  `;

