import axios from 'axios';
// const BASE_URL = 'http://192.168.0.68:3500';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
    baseURL: BASE_URL
});
export const new_axios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS'
    },
    withCredentials: false
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});


export async function apiFetch(...args) {
    let getFakeUser = [];
    await delay(Math.ceil(400 + Math.random() * 300));
    const res = await fetch(...args);
    const json = await res.json();
    json.data.push(getFakeUser());
    return json
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
