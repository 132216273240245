import React from 'react';

// import Hero from '../components/Hero/Hero';
// import AboutImg from '../assets/images/investment-1.png';
// import Footer from '../components/Footer/Footer';
import AboutUs from '../components/AboutUs/About';

const About = () => {
    return (
      <>
         {/* <Hero
          cName = 'hero-mid'
          heroImg = {AboutImg}
          title = 'About'
          btnClass = 'hide'
        /> */}
        < AboutUs />
        {/* <Footer /> */}
      </>
  )
}

export default About