import React from "react";

export default function StockFeed() {
  return (
    <div>
      <div className="main-stockfeed">
        <div className="stockfeed-row" >
          <div className="col-md-12" style={{ background: "#32a50560", display: "flex", width: "100%" }}>
            <div
              className="col-md-2"
              style={{
                overflow: "hidden",
                width: "14%",
                // whiteSpace: "nowrap",
                alignSelf: "center",
              }}
            >
              <h5>
                {" "}
                &nbsp;
                <strong style={{ marginLeft: "20px", fontSize: 18, color: "var(--blue-black" }}>
                  GSE STOCK FEED
                </strong>{" "}
              </h5>
            </div>
            <div
              className="col-md-10"
              style={{
                border: "none",
                width: "80%",
                height: "58px",
                alignSelf: "center",
                color: "#7107c5 !important",
              }}
            >
              <iframe
                src="https://gsestockfeed.com/"
                style={{
                  border: "none",
                  width: "100%",
                  height: "58px",
                  color: "#7107c5",
                  fontSize: "18px"
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
