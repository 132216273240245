import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCacheData, getFreshData } from '../../utils/request';
import './Gallery.css';

const GALLERY = '/api/gallery/categories';


function Gallery({ useCache}) {
    const [gallery, setGallery] = useState([]);
    
    const getGalleryData = async () => {
        setGallery([]);
        if (useCache) {
            const cachedGalleryData = getCacheData(GALLERY);
            if (cachedGalleryData) {
                setGallery(cachedGalleryData);
            }
        }
        
        const newGalleryData = await getFreshData(GALLERY, useCache);
        setGallery(newGalleryData);
    };
    
    useEffect(() => {
        getGalleryData();
    }, [])
    
    return (
        <section>
            <div className="content">
                <h3 className="heading"><i className="fas fa-photo" ></i> NTHC Photo Gallery</h3>
                <div className='gallery'>
                    <p>Select a gallery to view item</p>
                    <div className="gallerycard">                        
                        {gallery.map((item, i) => (  
                            <div className="gallery-card" key={i}>
                                <Link to={`/photos_group/${item.group}`}>
                                    <h4>{item.description}</h4>
                                    <div className='gallery-image'>
                                        <img alt='gallery-img' src={item.image} />
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
       </section>
    );
}

export default Gallery;
