import React, { useState } from 'react';
import './BoardStyles.css';
import Modal from '../Modal/Modal';

function BoardChair(props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            < Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
                <div>
                    <div style={{ textAlign: 'center' }}><h3>{props.position} Profile</h3></div>
                </div>
                <div className='profile-card'>
                    <img alt='board-img' src={`${process.env.REACT_APP_BASE_URL}/getimage/board/${props.image}`} width='200' height='200' />
                </div>
                <div>
                    <p><h3 style={{ textAlign: 'center' }}>{props.name}</h3></p><br />
                </div>
                <div>
                    {props.profile}
                </div><br />
            </Modal>
            <div className="board-chair-card">
                <div class="boardchair-wrapper">
                    <div className='boardchair-image'>
                        <img alt='boardImg' src={`${process.env.REACT_APP_BASE_URL}/getimage/board/${props.image}`} />
                    </div>
                    <h3>{props.name}</h3>
                    <h4>{props.position}</h4>
                    <div className='view-profile'>
                        <button onClick={() => setIsOpen(true)} className='btn btn-danger btn block'>
                            View Profile
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoardChair;