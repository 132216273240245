import { useState } from 'react';
import Modal from '../Modal/Modal';
import './ExecutivesStyles.css';

function ExecutiveMembers(props) {
    const [isOpen, setIsOpen] = useState(false);
    
    return (
        <>
        < Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
            <div>
                <div style={{ textAlign: 'center' }}><h3>{props.position} Profile</h3></div>
            </div>
            <div className='profile-card'>
                    <img alt='board-img' src={`${process.env.REACT_APP_BASE_URL}/getimage/executives/${props.image}`} width='200' height='200' />
                </div>
                <div>
                    <p><h3 style={{ textAlign: 'center' }}>{props.name}</h3></p><br />
                </div>
            <div>        
                {props.profile}
            </div><br />
        </Modal>
        <div className="executive-members-card">
            <div className='executive-image'>
                    <img alt='ExecImg' src={`${process.env.REACT_APP_BASE_URL}/getimage/executives/${props.image}`} />
            </div>
            <h3>{props.name}</h3>
            <h4>{props.position}</h4>
            <div>
                    <button onClick={() => setIsOpen(true)} className='btn  btn-danger'>
                    View Profile
                </button>  
            </div>
        </div>
        </>
    )
} 

export default ExecutiveMembers;