import React from 'react';

// import Hero from '../components/Hero/Hero';
import AboutImg from '../assets/slides/img-12.jpg';

const NotFound = () => {
    return (
      <section>
      <div className='content'>
       {/* <Hero
          cName = 'hero-mid'
          heroImg = {AboutImg}
          btnClass = 'hide'
        /> */}
          <h3>Page under construction</h3>
        </div>
        </section>
  )
}

export default NotFound