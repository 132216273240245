import React, { useEffect } from 'react';
import ReactPortal from '../ReactPortal/ReactPortal';
import './ModalStyles.css';

const Modal = ({ children, isOpen, handleClose}) => {
        
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        }
    }, [handleClose])
       if(!isOpen) return null
    
    return (
        <>
            <ReactPortal wrapperId="react-portal-modal-container">
                <div className="modal">
                    <div className="modal-content">
                        {children}
                        <button onClick={handleClose} className="btn">Close</button>
                    </div>
                </div>       
            </ReactPortal>
        </> 
    )
}

export default Modal