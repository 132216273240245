import React, { useEffect, useState } from 'react';
import SubsidiaryItem from './SubsidiaryItem';
import { getSubsidiaries } from '../../api/apiServices';
import './Subsidiary.css';
import { Link } from 'react-router-dom';
import sub from 'date-fns/fp/sub/index';
function Subsidiary() {

  const [subsidiaries, setSubsidiaries] = useState([]);

  const getSubsidiariesData = async () => {
    getSubsidiaries({ company: "NTHC_Ltd" }).then((data) => {
      setSubsidiaries(data);
    });
  }
  useEffect(() => {
    getSubsidiariesData();
  }, []);
 
  return (
    <div className='subsidiaries'>
      <h1>Our Subsidiaries</h1>
      <p>Strategic business units through which we undertake specific business activities within the varied sectors of the economy.</p>
      <div className='subsidiaries__container'>
        <div className='subsidiaries__wrapper'>
            <ul>
          <div className='subsidiaries__grid'>
              {subsidiaries?.map((subsidiary) =>
                <li key={subsidiary.id}>
                  <Link className='subsidiaries__item__link' target="_blank" to={subsidiary.path}>
                    <figure className='subsidiaries__item__pic-wrap' data-category={subsidiary.label}>
                      <img
                        className='subsidiaries__item__img'
                        alt='Travel'
                        src={`${process.env.REACT_APP_BASE_URL}/getimage/subsidiaries/${subsidiary.image}`}
                      />
                    </figure>
                    {/* <div className='subsidiaries__item__info'>
                      <h5 className='subsidiaries__item__text'>{subsidiary.description}</h5>
                    </div> */}
                  </Link>
                </li>
              )}
          </div>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Subsidiary;
