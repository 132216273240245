import React, { useEffect, useState } from 'react'
import axios from '../../api/axios';
import './LogoContent.css';

const MSG_URL = "/api/messages/get_message";

const LogoContent = () => {
    const [message, setMessage] = useState([]);

    const getMessage = async () => {
        setMessage([]);

        const newMessage = await axios.post(MSG_URL, {
            group: "NTHC Ltd",
        });
        setMessage(newMessage.data);
    };

    useEffect(() => {
        getMessage();
    }, []);


    return (
        <>
            <div className="welcome-content">
                <div className="welcome-content-left">
                    <div className="welcome-content-left">
                        <img
                            src={`${process.env.REACT_APP_BASE_URL}/getimage/messages/${message.image}`}
                        />
                    </div>
                </div>
                <div className="welcome-content-right">
                    <h1>Welcome to our corporate website</h1>
                    <p></p><p>It gives me great pleasure to extend a warm welcome to each and every one of you. Whether you are a potential client, partner, employee, or simply someone seeking information about NTHC, we are delighted to have you here.</p><br />
                    <p>We are the foremost Investment Bankers in Ghana with strategic interest in Real Estates and Commodities. Irrespective of your mission here, I also encourage you to get in touch to enjoy our renewed customer experience.</p><br />
                    <em><h2>Isaac Charles Acquah</h2></em>
                    <h3>Group Managing Director</h3>
                </div>
            </div>
        </>
    )
}

export default LogoContent