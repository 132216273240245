import axios from './axios';

const BOARD_URL = '/api/management/board';
const EXECUTIVES_URL = '/api/management/executives';
const GROUP_BUSINESS_URL = '/api/management/group_business_committee';

export async function getBoardMembers(company) {
    const response = await axios.post(BOARD_URL, company)
    return (response.data);
}
export async function getExecutiveMembers(company) {
    const response = await axios.post(EXECUTIVES_URL, company)
     return (response.data);
}
export async function getGroupBusinessMembers(company) {
    const response = await axios.post(GROUP_BUSINESS_URL, company)
     return (response.data);
}


// Slides
const GET_SLIDES = '/api/slides/get_slide_images';

export async function getSlideImages({company }) {
    const response = await axios.post(GET_SLIDES, { company: company })
    return response.data;
}

// Subsidiaries
const GET_SUBSIDIARIES = '/api/subsidiaries/all_subsidiaries';

export async function getSubsidiaries({ company }) {
    const response = await axios.post(GET_SUBSIDIARIES, { company: company })
    return response.data;
}