import React, { useState } from 'react';
import './ExecutivesStyles.css';
import Modal from '../Modal/Modal';

function GroupBusinessChair(props) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            < Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
                <div>
                    <div style={{ textAlign: 'center' }}><h3>{props.position} Profile</h3></div>
                </div>
                <div className='profile-card'>
                    <img alt='board-img' src={`${process.env.REACT_APP_BASE_URL}/getimage/executives/${props.image}`} width='200' height='200' />
                </div>
                <div>
                    <p><h3 style={{ textAlign: 'center' }}>{props.name}</h3></p><br />
                </div>
                <div>
                    {props.profile}
                </div><br />
            </Modal>
            <div className="executive-chair-card">
                <div className='executive-chair-image'>
                    <img alt='ExecImg' src={`${process.env.REACT_APP_BASE_URL}/getimage/executives/${props.image}`} />
                    <h3>{props.name}</h3>
                    <h4>{props.position}</h4>
                </div>
                <div>
                    <button onClick={() => setIsOpen(true)} className='btn btn-danger'>
                        View Profile
                    </button>
                </div>
            </div>
        </>
    )
}

export default GroupBusinessChair;