import React, { useState, useEffect, useRef } from "react";
import { getSlideImages } from "../../api/apiServices";
import "./ImageSlide.css";
import StockFeed from "../StockFeed/StockFeed";

const delay = 5000;

function ImageSlide() {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  const company = {
    company: "NTHC_Ltd",
  };
  useEffect(() => {
    getSlideImages(company).then((images) => {
      setSlides(images);
    });
  }, []);

  const totalSlides = slides.length - 1;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === totalSlides ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  });

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? totalSlides : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === totalSlides;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <>
      <div className="slideshow">
        <div className="leftArrowStyles" onClick={goToPrevious}>
          ❮
        </div>
        <div className="rightArrowStyles" onClick={goToNext}>
          ❯
        </div>
        <div
          className="slideshowSlider"
          style={{
            transform: `translate3d(${-currentIndex * 100}vw, 0, 0)`,
          }}
        >
          {slides?.map((slide, index) => (
            <div className="slide" key={index}>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/getimage/slides/${slide.image}`}
                alt="SlideImg"
              />
            </div>
          ))}
        </div>
        <StockFeed />
      </div>
    </>
  );
}
export default ImageSlide;
