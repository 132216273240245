import React, { useState, useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';

const InvestmentCalculator = () => {
    const [ principal, setPrincipal ] = useState();
    const [ rate, setRate ] = useState();
    const [ days, setDays] = useState();
    const [ tenor, setTenor] = useState();
    const [ returnData, setReturnData ] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    
    // const errRef = useRef();
    const principalRef = useRef();
  
    // const [errMsg, setErrMsg] = useState('');
      
       useEffect(() => {
        principalRef.current.focus();
       }, [])
    
    useEffect(() => {
        // setErrMsg('');
    }, [principal, rate, days, tenor])

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        try {
            const totalNumDays = 365;
            const daysLeft = days - tenor;
            var interest = 0;
            const resultData = [];
            
            if (parseInt(daysLeft) <= 0) {
                interest = (principal * (rate / 100) * (days / totalNumDays));

			    resultData.push({
				    principal: (parseInt(principal).toLocaleString(undefined, {minimumFractionDigits:2})),
				    rate    :parseInt(rate),
				    days	: parseInt(days),
				    tenor :		parseInt(tenor),
				    interest  	: interest.toLocaleString(undefined, {minimumFractionDigits:2}),
				    invest_val	: (parseInt(principal) + parseInt(interest)).toLocaleString(undefined, {minimumFractionDigits:2})
                });
                
		    } else {
			    //For days that are beyond the tenor
                let daysTracker = days;
                let tenorTracker = tenor;
                let startDays = 0;
                let cummPrincipal = parseFloat(principal)
                
                do {
				    if(parseInt(daysTracker) < parseInt(tenorTracker)) {
                        startDays = daysTracker;
                    } else {
                        startDays = tenorTracker
                    }
                  
                    interest = parseFloat((cummPrincipal * (rate / 100) * (startDays / totalNumDays)));
                    
			        resultData.push({
				        principal : new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(cummPrincipal).toFixed(2)),
				        rate    :parseFloat(rate),
				        days	: parseInt(startDays),
				        tenor :		parseInt(tenor),
				        interest  	: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(parseFloat(interest).toFixed(2)),
                        invest_val: new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format((parseFloat(cummPrincipal) + parseFloat(interest)).toFixed(2))
                    });
                    cummPrincipal = parseFloat(cummPrincipal) + parseFloat(interest);
				    daysTracker -= tenor;
			    } while (daysTracker > 0)
		}
            setReturnData(resultData);
        } catch (error) {
           console.log(error) 
        }
    }

    return (
       
            <div className="content">
            < Modal handleClose={() => {
                setIsOpen(false)
                setPrincipal('')
                setRate ('')
                setDays('')
                setTenor('')
                setReturnData ([])
            }} isOpen={isOpen}>
                        <h1 class="heading"><i className="fas fa-calculator" ></i> Computed Investment </h1>
                        <table className="table table-borderless table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Principal</th>
                                    <th>Rate</th>
                                    <th>No. of Days</th>
                                    <th>Investment Tenor</th>
                                    <th>Interest</th>
                                    <th>Investment Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {returnData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: "center" }}>{index+1}</td>
                                            <td style={{ textAlign: "right" }}>{item.principal.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {item.rate}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {item.days}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {item.tenor} Days
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                {item.interest}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                                {item.invest_val}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* <a href='/investment_calculator' class="btn"><strong>Exit</strong></a> */}
                </Modal>
                <div className="form-container">
                    <h3 class="heading"><i className="fas fa-calculator" ></i> Investment Calculator</h3>
                    <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="principal" className="col-sm-2 col-form-label">Principal Amount<span>*</span></label>
                        <div className="col-sm-5">
                            <input
                                type="number"
                                ref={principalRef}
                                id="principal"
                                placeholder='Principal Amount'
                                autoCompconste="off"
                                onChange={(e) => setPrincipal(e.target.value)}
                                value={principal}
                                required
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="rate" className="col-sm-2 col-form-label">Investment Rate<span>*</span></label>
                        <div className="col-sm-5">
                            <input
                                type="number"
                                ref={principalRef}
                                id="rate"
                                placeholder='Expected % rate'
                                autoCompconste="off"
                                onChange={(e) => setRate(e.target.value)}
                                value={rate}
                                required
                                className="form-control"
                            />
                            <div class="info-text">* Entered rate is assumed to be constant throughout</div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="days" className="col-sm-2 col-form-label">Number of Days<span>*</span></label>
                        <div className="col-sm-5">                
                            <input
                                type="number"
                                ref={principalRef}
                                id="days"
                                placeholder='Number of days investment is expected to travel'
                                autoCompconste="off"
                                onChange={(e) => setDays(e.target.value)}
                                value={days}
                                required
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="tenor" className="col-sm-2 col-form-label">Investment Tenor<span>*</span></label>
                        <div className="col-sm-5">
                            <select
                                type="number"
                                ref={principalRef}
                                id="tenor"
                                placeholder='Number of days investment is expected to travel'
                                autoCompconste="off"
                                onChange={(e) => setTenor(e.target.value)}
                                value={tenor}
                                required
                                className="form-control"
                            >
                            <option value="">Select Investment Tonor</option>
                            <option value='91'>91 days</option>
                            <option value='182'>182 days</option>
                            <option value='365'>365 days</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="" className="col-sm-2 col-form-label"></label>
                        <div className="col-sm-5">
                            <button type="submit" className="btn btn-success" onClick={() => setIsOpen(true)}>Submit</button>
                        </div>
                    </div>                   
                    </form>
                </div>
            </div>
        
    )
}
export default InvestmentCalculator


