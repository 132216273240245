import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from "../../routes/Home";
import About from "../../routes/About";
import Contact from "../../routes/Contact";
// import Board from "../Board/Board";
// import Executives from '../Executives/NewExecutives/Executives';
// import GroupBusiness from "../Business/GroupBusiness";
import NotFound from "../../routes/NotFound";
import NewsLetters from "../Research/Newsletters";
import Articles from "../Research/Articles";
import EquityResearch from '../Research/EquityResearch';
import MarketReview from '../Research/MarketReview';
import Gallery from '../Gallery/Gallery';
// import GalleryItem from '../Gallery/GalleryItems';
import GalleryPhotos from '../Gallery/GalleryPhotos';
import Calculator from '../InvestmentCalculator/InvestmentCalculator';
import Layout from '../Layout/Layout';
import Download from '../Downloads/Downloads';
import BoardInfo from '../Board/BoardInfo';
import ExecutiveInfo from '../Executives/ExecutiveInfo';
import GroupBusinessInfo from '../Business/GroupBusinessInfo';


const App = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const group  = pathname.substring(14);
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/board" element={<Board />} /> */}
          <Route path="/board" element={<BoardInfo />} />
          <Route path="/executives" element={<ExecutiveInfo />} />
          <Route path="/group" element={<GroupBusinessInfo />} />
          <Route path="/newsletters" element={<NewsLetters />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/equity_research" element={<EquityResearch />} />
          <Route path="/market_review" element={<MarketReview />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path={`/photos_group/${group}`} element={<GalleryPhotos value={ group } />} />  
          <Route path="/investment_calculator" element={<Calculator />} />
          <Route path="/form_downloads" element={<Download />} />
          <Route path="*" element={<NotFound />} />          
        </Route>
      </Routes>
    </>
  );
};

export default App;

