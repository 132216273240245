import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import MessageBox from '../MessageBox/MessageBox';
import './ContactFormStyles.css';
import axios from '../../api/axios';

const CONTACT_URL = '/api/contact/contact_us';


const ContactForm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [first_name, setFirstname] = useState('');
    const [last_name, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [message, setMessage] = useState('');
    const [msgBox, setMsgBox] = useState('');

    const [errMsg, setErrMsg] = useState('');


    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname;

    const firstnameRef = useRef();

    useEffect(() => {
        firstnameRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [first_name, last_name, email, telephone, message])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(CONTACT_URL,
                { first_name, last_name, email, telephone, message }
            ).then((response) => {
                setIsSubmitted(true)
                setMsgBox(response.data);
                setFirstname('');
                setLastname('');
                setEmail('');
                setTelephone('');
                setMessage('');
                navigate(from, { replace: true });
            })
            console.log(response)
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            {isSubmitted && (
                <>< MessageBox handleClose={() => {
                    setIsOpen(false)
                    setIsSubmitted(false)
                    setMsgBox('')
                }}
                    isOpen={isOpen}
                >
                    <div>
                        <div className="title"><h3>NTHC Limited</h3></div>
                        <div className="message-bpx">
                            {msgBox}
                        </div><br />
                    </div>
                </MessageBox></>
            )}
            <div className="content">
                <h3 class="heading"><i className="fas fa-envelope" ></i> Send us a message</h3>
                <div className="contact">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="first_name" className="col-sm-3 col-form-label">First Name</label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        ref={firstnameRef}
                                        id="first_name"
                                        placeholder='First Name'
                                        autoComplete="off"
                                        onChange={(e) => setFirstname(e.target.value)}
                                        value={first_name}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="last_name" className="col-sm-3 col-form-label">Last Name</label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        ref={firstnameRef}
                                        id="last_name"
                                        placeholder='Last Name'
                                        autoComplete="off"
                                        onChange={(e) => setLastname(e.target.value)}
                                        value={last_name}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                            <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
                                <div className="col-sm-">
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder='Email'
                                        autoComplete="off"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="telephone" className="col-sm-3 col-form-label">Telephone</label>
                                <div className="col-sm-4">
                                    <input
                                        type="number"
                                        id="telephone"
                                        placeholder='Telephone Number'
                                        autoComplete="off"
                                        onChange={(e) => setTelephone(e.target.value)}
                                        value={telephone}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group ">
                                <label htmlFor="message" className="col-sm-3 col-form-label">Message</label>
                                <div className="col-sm-4">
                                    <textarea
                                        rows="8"
                                        type="textarea"
                                        id="message"
                                        autoComplete="off"
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message}
                                        required
                                        name="message"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                    <div className="form-group">
                        <label htmlFor="" className="col-sm-3 col-form-label"></label>
                        <div className="col-sm-4">
                            <button type="submit" className="btn btn-success" onClick={() => setIsOpen(true)}>Submit</button>
                        </div>
                    </div>                   
                </form>
                    </div>
                    <div className="box-container">
                        <div className="box">
                            <i className="fas fa-phone"></i>
                            <h3>Telephone Number</h3>
                            <a href="tel:233302240241">233-302-240241</a>
                            <a href="tel:233302240242">233-302-240242</a>
                        </div>

                        <div className="box">
                            <i className="fas fa-envelope"></i>
                            <h3>Email Address</h3>
                            <a href="mailto:info@nthc.com.gh">info@nthc.com.gh</a>
                            <a href="mailto:mailer@nthc.com.gh">mailer@nthc.com.gh</a>
                        </div>

                        <div className="box">
                            <i className="fas fa-map-marker-alt"></i>
                            <h3>GPS Location</h3>
                            <p>GS-031-5968</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm