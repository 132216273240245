import NthcLogo from "../../assets/logos/nthclogo.png";
import './FooterStyles.css';

const Footer = () => {
    // const today = new Date();
    return (
        <footer className='footer'>
           
                <div className="top-image">
                    <img src="/images/logos/nthc_logo.png" alt='NTHC Logo' />
                </div>

            <div className='top'>
                <div className='item'>
                    <h1>Locate Us</h1>
                    <p>Head Office,</p>
                    <p>18 Gamel Abdul Nasser Avenue,</p>
                    <p>Ringway Estates,</p>
                    <p>Osu-Accra.</p>
                    <p>GPS: GS-031-5968</p>
                </div>
                <div className='item'>
                    <h1>Postal Address</h1>
                    <p>Head Office,</p>
                    <p>P. O. Box KIA 9563</p>
                    <p>Airport-Accra, Ghana.</p>
                </div>
                <div className='item'>
                    <h1>Email</h1>
                    <a href="mailto:info@nthc.com.gh">info@nthc.com.gh</a><br />
                    <a href="mailto:mailer@nthc.com.gh">mailer@nthc.com.gh</a>
                </div>
                <div className='item'>
                    <h1>Telephone</h1>
                    <a href="tel:233302240241">233-302-240241</a><br />
                    <a href="tel:233302240242">233-302-240242</a>
                </div>
                <div className='item'>
                    <h1>Follow us on</h1>
                    <a target="_blank" rel='noreferrer' href='https://facebbok.com/nthc_gh'>
                        <i className='fab fa-facebook square'></i>
                    </a>
                    <a target="_blank" rel='noreferrer' href='https://instagram.com/nthc_gh'>
                        <i className='fa-brands fa-instagram-square'></i>
                    </a>
                    <a target="_blank" rel='noreferrer'  href="https://www.linkedin.com/company/nthc"><i className="fab fa-linkedin"></i></a>
                    <a target="_blank" rel='noreferrer' href='https://twitter.com/NTHC_GH'>
                        <i className='fa-brands fa-twitter-square'></i>
                    </a>                   
                </div>
            </div>
            <div className='bottom'>
                <div className='item'>
                    <h1>Our Subsidiaries</h1>
                    <a target="_blank" rel='noreferrer' href="https://securities.nthc.com.gh">NTHC Securities Ltd</a>
                    <a target="_blank" rel='noreferrer' href="https://registrars.nthc.com.gh">NTHC Registrars Ltd</a>
                    <a target="_blank" rel='noreferrer' href="https://trustees.nthc.com.gh">NTHC Trustees Ltd</a>
                    <a target="_blank" rel='noreferrer' href="https://asset-management.nthc.com.gh">NTHC Asset Management Ltd</a>
                    <a target="_blank" rel='noreferrer' href="https://commodities.nthc.com.gh">NTHC Commodities Ltd</a>
                    {/* <a target="_blank" rel='noreferrer' href="https://beta-properties.nthc.com.gh">NTHC Properties Ltd</a> */}
                    <a target="_blank" rel='noreferrer' href="https://nthcpghana.com/">NTHC Properties Ltd</a>
                </div>
                 <div className='item'>
                    <h1>Resources</h1>
                    <a href="/form_downloads">Forms Download</a>
                    <a href="/newsletters">Newsletters</a>
                    <a href="/articles">Articles</a>
                    <a href="/investment_calculator">Investment Calculator</a>
                    <a href="/gallery">Gallery</a>
                </div>
                <div className='item'>
                    <h1>Research</h1>
                    <a href="/equity_research">Equity Research</a>
                    <a href="/market_review">Market Review</a>
                </div>
                <div className='item'>
                    <h1>Help</h1>
                    <a href="/jobs">Jobs</a>
                    <a href="/contact">Contact Us</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
