export const routes = [
	{
		name: "Home",
		link: "/",
	},
	{
		name: "Our Company",
		link: "/#",

		subRoutes: [
			{
				name: 'About Us',
				link: '/about',
			},
			{
				name: 'Board of Directors',
				link: '/board',
			},
			{
				name: 'Executive Team',
				link: '/executives',
			},
			{
				name: 'Group Business Team',
				link: '/group',
			},
		],
	},
	{
		name: "Research",
		link: "/#",

		subRoutes: [
			{
				name: 'Market Review',
				link: '/market_review',
			},
			{
				name: 'Newsletters',
				link: '/newsletters',
			},
			{
				name: 'Articles',
				link: '/articles',
			},
			{
				name: 'Equity Research',
				link: '/equity_research',
			},
		]
	},
	{
		name: "Downloads",
		link: "/#",

		subRoutes: [
			{
				name: 'Forms Download',
				link: '/form_downloads',
			},
		]

	},
	{
		name: 'Gallery',
		link: '/gallery',
	},
	{
		name: 'Contact Us',
		link: '/contact',
	},
];
