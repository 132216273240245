import React, { useState } from 'react';
// import Navbar from '../Navbar/Navbar';
import Navigation from '../Navigation/Navigation';
import { Link } from 'react-router-dom';
import NthcLogo from "../../assets/logos/nthc_logo.png";
import Marquee from "react-fast-marquee";
import './Header.css';
import MarqueeData from '../Marquee/MarqueeData';

const Header = () => {
  const [click, setClick] = useState(false);

  const closeMobileMenu = () => setClick(!click);

  return (

    <div className="navbar-area">

      <div className='top-area'>
        <Marquee>
          <MarqueeData />
        </Marquee>
      </div>
      <div className='nav-area'>
        <Link to="/" >
          <div className="nav-image">
            <img src={NthcLogo} alt='NTHC Logo' onClick={closeMobileMenu} />
          </div>
        </Link>
        <Navigation />
      </div>
    </div>

  );
};

export default Header;
