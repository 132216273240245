import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { getCacheData, postData } from '../../utils/request';
import './GalleryPhotosStyles.css';

const GALLERY_GRP = `/api/gallery/group_photos`;

const delay = 5000;

function GalleryPhotos({ useCache, value }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const timeoutRef = useRef(null);
	
  	const [galleryGroup, setGalleryGroup] = useState([]);
   const [ click, setClick ] = useState(true);
    
    const handleClick = () => setClick(!click);
  
  	const getGalleryGroupData = async () => {
    	setGalleryGroup([]);
    	if (useCache) {
      		const cachedGalleryGroupData = getCacheData(GALLERY_GRP, value);
      		if (cachedGalleryGroupData) {
        		setGalleryGroup(cachedGalleryGroupData);
      		}
    	}
    
    	const newGalleryGroupData = await postData(GALLERY_GRP, useCache, {
      		group: value
    	});
    	setGalleryGroup(newGalleryGroupData);
  	};
  
  	useEffect(() => {
    	getGalleryGroupData();
  	}, [])
  
	const totalSlides = galleryGroup.length - 1;
    
    function resetTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    useEffect(() => {      
      resetTimeout();
      timeoutRef.current = setTimeout(() =>
        setCurrentIndex((prevIndex) => prevIndex === totalSlides ? 0 : prevIndex + 1),
        delay
      );
      return () => {
        resetTimeout();
      };
    });

  const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? totalSlides : currentIndex - 1;
        setCurrentIndex(newIndex)
    }
  const goToNext = () => {
        const isLastSlide = currentIndex === totalSlides ;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)       
    }

  
  if (!Array.isArray(galleryGroup) || galleryGroup.length <= 0) {
    return null;
  }
  
    return (
        <div className='photo-slider'>
          
					  <Link to="/gallery" className='close-icon' >
						  <i className="fas fa-times" ></i>   
					  </Link>          
              <h1 className="photo-header">{galleryGroup[0].description}</h1>
			

        <div className='photo-slide'>
              <div className="left-arrow"  onClick={goToPrevious}>❮</div>
      				<div className="right-arrow" onClick={ goToNext}>❯</div>
                		{galleryGroup.map((slide, index) => {
                  		return (
            				<div
              					className={index === currentIndex ? 'photo-slide active' : 'photo-slide'}
              					key={index}
            				>
              					{index === currentIndex && (
                          <img src={slide.image} alt='photos' />
                          )}
            				</div>
          				);
                })}
                </div>          

           
      </div>
  );
}
export default GalleryPhotos