/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GroupBusinessChair from './GroupBusinessChair';
import GroupBusinessMembers from './GroupBusinessMembers';
import { getCacheData, getFreshData, postData } from '../../utils/request';
import './GroupBusinessStyles.css';

const GROUP_BUSINESS_COMMITTEE_URL = '/api/management/group_business_committee';


function GroupBusinessInfo({ useCache }) {
    const [groupBusiness, setGroupBusiness] = useState([]);

    const getGroupData = async () => {
        setGroupBusiness([]);

        if (useCache) {
            const cachedGroupData = getCacheData(GROUP_BUSINESS_COMMITTEE_URL);
            if (cachedGroupData) {
                setGroupBusiness(cachedGroupData)
            }
        }
        // const newGroupData = await getFreshData(GROUP_BUSINESS_COMMITTEE_URL, useCache);

        const newGroupData = await postData(GROUP_BUSINESS_COMMITTEE_URL, useCache, {
            company: "NTHC_Ltd",
        });
        setGroupBusiness(newGroupData);
    };

    useEffect(() => {
        getGroupData();
    }, [])

    return (
        <div className="content">
            <h3 className="heading">Group Business Team</h3>
            <div className="group">
                <div className="groupcard">
                    {groupBusiness && groupBusiness.length > 0 && groupBusiness.map((obj, index) => (
                        <>
                            {obj.rank === 1 && (
                                <GroupBusinessChair
                                    key={index}
                                    image={obj.image}
                                    name={obj.name}
                                    position={obj.position}
                                    profile={obj.profile}
                                />
                            )}
                        </>
                    ))}
                    <div className="group-members">
                        {groupBusiness && groupBusiness.length > 0 && groupBusiness.map((obj, index) => (
                            <>
                                {obj.rank !== 1 && (
                                    <GroupBusinessMembers
                                        key={index}
                                        image={obj.image}
                                        name={obj.name}
                                        position={obj.position}
                                        profile={obj.profile}
                                    />
                                )}
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupBusinessInfo;