/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { getCacheData, getFreshData } from '../../utils/request';


const EQUITY_URL = '/api/research/equity_research';

const EquityResearch = ( { useCache }) => {
	const [equityResearch, setEquityResearch] = useState([]);
    
	const getEquityResearch = async () => {
		setEquityResearch([]);
       
        if (useCache) {
			const cachedEquityResearch = getCacheData(EQUITY_URL);
			if (cachedEquityResearch) {
				setEquityResearch(cachedEquityResearch)
            }
        }
		const newEquityResearch = await getFreshData(EQUITY_URL, useCache);
		setEquityResearch(newEquityResearch);
    };

    useEffect(() => {
		getEquityResearch();
    }, [])
        
  	return (    	
		<div className="content">
				<h3 className="heading">Equity Research</h3><br />
			<p>
			NTHC Equity Research on Ghana’s money and capital markets during the specific period.
			</p><br />
			<div className="data-table">
				<table className="table table-borderless table-sm table-hover">
					<thead className="align">
						<tr>
							<th>File</th>
							<th>Description</th>
							<th>Edition</th>
							<th>Date</th>
							<th>Downloads</th>
						</tr>
					</thead>
					<tbody>
							{equityResearch.length > 0 ? equityResearch.map((item => {	
							return(
								<tr >
									<td style={{color:'brown'}}><i className="fa fa-file-pdf"></i></td>
									<td><a rel="noreferrer" target="_blank" href={`/uploads/research/${ item.file_name }`}>{item.description}</a> </td>
									<td>{`Edition ${item.edition}`}</td>
									<td>{item.date}</td>
									<td><a rel="noreferrer" target="_blank" download={"NTHC_Articles_" + item.date + ".pdf"} href={`/uploads/research/${ item.file_name }`}><i className="fa fa-cloud-download"></i>&nbsp;&nbsp; Download
									</a></td>
								</tr>
							)	
						})) : (	
								
									<div>
									<tr>
										<td colspan="5">
									<h3>Equity Research are yet to be uploaded</h3>
										</td>
									</tr>
								</div>
							)}
					</tbody>
				</table>
			</div>
		</div> 	
  	)
}

export default EquityResearch