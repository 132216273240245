/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { getCacheData, getFreshData } from "../../utils/request";
import Pagination from "../../utils/Pagination";
import "./Research.css";

const MKT_REVIEW_URL = "/api/research/market_review";

const MarketReview = ({ useCache }) => {
  const [marketReview, setMarketReview] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);

  const getMarketReview = async () => {
    setMarketReview([]);

    if (useCache) {
      const cachedMarketReview = getCacheData(MKT_REVIEW_URL);
      if (cachedMarketReview) {
        setMarketReview(cachedMarketReview);
      }
    }
    const newMarketReview = await getFreshData(MKT_REVIEW_URL, useCache);
    setMarketReview(newMarketReview);
  };

  useEffect(() => {
    getMarketReview();
  }, [currentPage]);

  const indexOfLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexOfLastPage - postPerPage;
  const currentPosts = marketReview.slice(indexOfFirstPage, indexOfLastPage);

  return (
    <div className="content">
      <h3 className="heading">Market Review</h3>
      <br />
      <p>
        NTHC weekly report summarizing activities on Ghana’s money and capital
        markets during the specific period.
      </p>
      <br />
      <div className="data-table">
        <table className="table table-borderless table-sm table-striped table-hover">
          <thead className="align">
            <tr>
              <th scope="col">#</th>
              <th scope="col">File</th>
              <th scope="col">Description</th>
              <th scope="col">Edition</th>
              <th scope="col">Date</th>
              <th scope="col">Downloads</th>
            </tr>
          </thead>
          {currentPosts.length > 0 ? (
            currentPosts.map((item, index) => {
              return (
                <>
                  <tbody key={index}>
                    <tr>
                      <td>{postPerPage * (currentPage - 1) + index + 1}</td>
                      <td style={{ color: "brown" }}>
                        <i className="fa fa-file-pdf"></i>
                      </td>
                      <td>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${process.env.REACT_APP_BASE_URL}/getimage/research/${item.file_name}`}
                        >
                          {item.description}
                        </a>{" "}
                      </td>
                      <td>{`Edition ${item.edition}`}</td>
                      <td>{item.date}</td>
                      <td>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          download={"NTHC_MarketReview_" + item.date + ".pdf"}
                          href={`${process.env.REACT_APP_BASE_URL}/getimage/research/${item.file_name}`}
                        >
                          <i className="fa fa-cloud-download"></i>&nbsp;&nbsp;
                          Download
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })
          ) : (
            <tbody>
              <tr>
                <td colSpan="5">
                  <h3>Market Reviews are not available</h3>
                </td>
              </tr>
            </tbody>
          )}
          <tr>
            <td colSpan={5}>
              <div style={{ float: "left" }}>
                <Pagination
                  data={marketReview}
                  postPerPage={postPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default MarketReview;
