import React from 'react'
import './Marquee.css';

const MarqueeData = () => {
    return (
        <div className="marquee">
            <div className='nthc-ltd'>
                <h1>NTHC Limited :</h1>
                <h2>Where Your Money Grows !</h2>
            </div>
            <div className='marquee-text'>
                <h1>Phone :</h1>
                <h2>233-302-240241/42</h2>
            </div>
            <div className='marquee-text'>
                <h1>Email :</h1>
                <h2>info@nthc.com.gh</h2>
            </div>

            <div className="marquee-text">
                <h1>Follow us on</h1>
                <h2>
                    <a target="_blank" rel="noreferrer" href="https://facebbok.com/nthc_gh"><i className="fab fa-facebook square"></i> </a>
                    <a target="_blank" rel="noreferrer" href="https://instagram.com/nthc_gh"><i className="fa-brands fa-instagram-square"></i> </a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/nthc"><i className="fab fa-linkedin"></i> </a>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/NTHC_GH"><i className="fa-brands fa-twitter-square"></i> </a>
                </h2>
            </div>
        </div>
    )
}

export default MarqueeData