import React, { useState, useEffect } from 'react';
import "./AboutUsStyles.css";

const About = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [activeTab, setActiveTab] = useState("history");

    return (
        <div className="content">
            <h3 className="heading"> About Us</h3>
            <div className='tabs'>
                <ul className='nav'>
                    <TabNavItem title="Our History" id="history" activeTab={activeTab} setActiveTab={setActiveTab} />
                    <TabNavItem title="Mission & Vision" id="mission" activeTab={activeTab} setActiveTab={setActiveTab} />
                    <TabNavItem title="Core Functions" id="functions" activeTab={activeTab} setActiveTab={setActiveTab} />
                    {/* <TabNavItem title="Board of Directors" id="board" activeTab={activeTab} setActiveTab={setActiveTab} /> */}
                    <TabNavItem title="Our Shareholders" id="shareholders" activeTab={activeTab} setActiveTab={setActiveTab} />
                    <TabNavItem title="Our Subsidiaries" id="subsidiaries" activeTab={activeTab} setActiveTab={setActiveTab} />
                    {/* <TabNavItem title="Operating Structure" id="structure" activeTab={activeTab} setActiveTab={setActiveTab} /> */}
                    <TabNavItem title="Track Record" id="record" activeTab={activeTab} setActiveTab={setActiveTab} />
                </ul>
                <div className='outlet'>
                    <TabContent id="history" activeTab={activeTab}>
                        <History />
                    </TabContent>
                    <TabContent id="mission" activeTab={activeTab}>
                        <MissionVisionValues />
                    </TabContent>
                    <TabContent id="functions" activeTab={activeTab}>
                        <Functions />
                    </TabContent>
                    <TabContent id="board" activeTab={activeTab}>
                        <Board />
                    </TabContent>
                    <TabContent id="shareholders" activeTab={activeTab}>
                        <Shareholders />
                    </TabContent>
                    <TabContent id="subsidiaries" activeTab={activeTab}>
                        <Subsidiaries />
                    </TabContent>
                    {/* <TabContent id="structure" activeTab={activeTab}>
                        <OperatingStructure />
                    </TabContent> */}
                    <TabContent id="record" activeTab={activeTab}>
                        <TrackRecord />
                    </TabContent>

                </div>
            </div>
        </div>
    )
}
export default About

function TabNavItem({ id, title, activeTab, setActiveTab }) {
    const handleClick = () => {
        setActiveTab(id);
    };
    return (
        <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
            {title}
        </li>
    )
}
function TabContent({ id, activeTab, children }) {
    return (
        activeTab === id ? <div className='TabContent'>
            {children}
        </div>
            : null
    )
}

function History() {
    return (
        <div className='history'>
            <h1>Our History</h1>
            <p>The National Trust Holding Company (NTHC) Limited was established in 1976 (under the auspices of the National Investment Bank) by incorporation under the Companies Code, 1963 (Act 179). In that same year, NTHC was authorized by Legislative Instrument (L.I 1093/76) to operate as a Mutual Fund. The key initial objectives in its establishment were for NTHC to:</p><br />
            <h1>Our Mission</h1>
            <p>To create and provide a reliable array of investment options that fulfils the appetite of varied investors leveraging research, innovation and professionalism at all times</p><br />

            <h1>Our Vision</h1>
            <p>To be respected as the fore-runner in Investment Banking value creation and a model financial sector institution across Ghana and beyond.</p><br />

            <h1>Our Core Values (ETIRI)</h1>
            <ul>
                <li>
                    <p>
                        <strong>Excellence :</strong> The quest to achieve excellence must be viewed as a continuous process and not an end. Employees must always seek to achieve excellence by offering superior service and making daily contributions that would promote excellence over the immediate to long-term.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Teamwork :</strong> Creating a healthy environment that encourages team work and promotes cross-referencing among peers is perhaps a major step to driving innovation by enabling all personnel to develop a deep feeling of belonging. This would encourage the sharing of ideas and promote the radicalisation of new thinking to the benefit of the Company.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Integrity :</strong> Remaining competitive requires high levels of integrity and ethical behaviour. This is critical to preserve the Company’s trade secrets, remain competitive and attain leadership position.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Responsibility :</strong> Embracing the opportunity to contribute towards the achievement of the objectives of the company and accepting that their actions impact everyone around them.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Innovation :</strong> Innovation must be viewed as the most critical independent resource required of NTHC’s human capital to operate and to achieve its vision. Focusing on the opportunities in the industry and being ahead of competitors to introduce new ideas/products in the industry.
                    </p>
                </li>
            </ul>

        </div>
    )
}
function MissionVisionValues() {
    return (
        <div className='mission'>
            <h1>Our Mission</h1>
            <p>To create and provide a reliable array of investment options that fulfils the appetite of varied investors leveraging research, innovation and professionalism at all times</p><br />

            <h1>Our Vision</h1>
            <p>To be respected as the fore-runner in Investment Banking value creation and a model financial sector institution across Ghana and beyond.</p><br />

            <h1>Our Core Values (ETIRI)</h1>
            <ul>
                <li>
                    <p>
                        <strong>Excellence :</strong> The quest to achieve excellence must be viewed as a continuous process and not an end. Employees must always seek to achieve excellence by offering superior service and making daily contributions that would promote excellence over the immediate to long-term.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Teamwork :</strong> Creating a healthy environment that encourages team work and promotes cross-referencing among peers is perhaps a major step to driving innovation by enabling all personnel to develop a deep feeling of belonging. This would encourage the sharing of ideas and promote the radicalisation of new thinking to the benefit of the Company.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Integrity :</strong> Remaining competitive requires high levels of integrity and ethical behaviour. This is critical to preserve the Company’s trade secrets, remain competitive and attain leadership position.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Responsibility :</strong> Embracing the opportunity to contribute towards the achievement of the objectives of the company and accepting that their actions impact everyone around them.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Innovation :</strong> Innovation must be viewed as the most critical independent resource required of NTHC’s human capital to operate and to achieve its vision. Focusing on the opportunities in the industry and being ahead of competitors to introduce new ideas/products in the industry.
                    </p>
                </li>
            </ul>
        </div>
    )
}

function Functions() {
    return (
        <div className='core-functions'>
            <h1>Core Functions</h1>
            <p>We have strategic business units through which we undertake specific business activities within the varied sectors of the economy.</p>
            <p>These strategic business units are incorporated as subsidiary companies to provide diversity of investments opportunities.</p>
            <p> The core functions of the strategic business units are the following:</p>
            <ul>
                <li>
                    <p><strong>•</strong> Corporate Finance Advisory</p>
                </li>
                <li>
                    <p><strong>•</strong> Asset Management Business</p>
                </li>
                <li>
                    <p><strong>•</strong> Securities Trading</p>
                </li>
                <li>
                    <p> <strong>•</strong> Shares Registrar & Company Secretarial Business</p>
                </li>
                <li>
                    <p><strong>•</strong> Pensions Trustees Business</p>
                </li>
                <li>
                    <p><strong>•</strong> Commodities Trading Business</p>
                </li>
                <li>
                    <p><strong>•</strong> Real Estates Business</p>
                </li>
            </ul>
        </div>
    )
}
function Board() {
    return (
        <div className='board-composition'>
            <h1>Board of Directors</h1>
            <p> The Board of Directors are responsible for the policy direction of the company.</p>
            <div className='sub-board'>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Daniel_Kwaku_Ohene_Owusu.png" />
                    </div>
                    <h4>Daniel Kwaku Ohene Owusu</h4>
                    <h5>Ag. Chairman</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Kofi_Agyeman_Boakye.png" />
                    </div>
                    <h4>Kofi-AgyemanBoakye</h4>
                    <h5>Board Member</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Isaac_Charles_Acquah.png" />
                    </div>
                    <h4>Isaac Charles Acquah</h4>
                    <h5> Board Member</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Frank_Sylvanus_Aidoo.png" />
                    </div>
                    <h4>Frank Sylvanus Aidoo</h4>
                    <h5>Board Member</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Nana_Yaw_Mantey.png" />
                    </div>
                    <h4>Nana Yaw Mantey</h4>
                    <h5>Board Member</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Joseph_Appau_Boateng.png" />
                    </div>
                    <h4>Joseph Appau Boateng</h4>
                    <h5>Board Member</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Thomas_Osei_Antwi.png" />
                    </div>
                    <h4>Thomas Antwi-Osei</h4>
                    <h5>Board Member</h5>
                </div>
                <div className="sub-board-card">
                    <div className='sub-board-image'>
                        <img alt='boardImg' src="/images/board/Henry_Orraca-Tetteh.png" />
                    </div>
                    <h4>Henry Orraca-Tetteh</h4>
                    <h5>Board Member</h5>
                </div>
            </div>
        </div>
    )
}

function Shareholders() {
    return (
        <div className='shareholders'>
            <h1> Our Shareholders</h1>
            <div className="shareholders-card">
                <div className="holders-card">
                    <div className="col-md-2 logo-image">
                        <img src="/images/shareholders/ssnit_logo.jpg" alt="SSNIT Logo" width="512" height="512" />
                    </div>
                    <div>
                        <div><h3>Social Security and National Insurance Trust (SSNIT)</h3></div>

                        <div><p>The Social Security and National Insurance Trust (SSNIT), established in 1972, is a statutory public Trust
                            charged under the National Pensions Act 2008 Act 766 with the administration of Ghana’s Basic National
                            Social Security Pension Scheme and to cater for the first tier of the contributory three-tier scheme. Today
                            it manages one of the largest asset portfolios in Ghana. SSNIT currently holds <strong>75%</strong> shareholding in NTHC Limited.</p></div>
                    </div>
                </div>
                <div className="holders-card">
                    <div className="col-md-2 logo-image">
                        <figure>
                            <img src="/images/shareholders/nib.png" alt="NIB Logo" width="512" height="512" />
                        </figure>
                    </div>
                    <div>
                        <div><h3>Investment Bank (NIB)</h3></div>
                        <div>
                            <p>National Investment Bank (NIB), was incorporated in 1963 as an autonomous joint state-private institution
                                primarily to promote and strengthen rapid industrialization in all sectors of the Ghanaian economy. Today it
                                is one of the oldest established banks in the country. NIB currently holds <strong>8.71%</strong>
                                shareholding in NTHC Limited.</p>
                        </div>
                    </div>
                </div>
                <div className="holders-card">
                    <div className="col-md-2 logo-image">
                        <figure>
                            <img src="/images/shareholders/sic_logo.png" alt="SIC Logo" width="512" height="512" />
                        </figure>
                    </div>
                    <div>
                        <div><h3>Insurance Company Limited (SIC)</h3></div>
                        <div>
                            <p>State Insurance Company Limited (SIC) traces its roots to 1955. It is the nation’s largest insurer and
                                the
                                only one with a nationwide coverage. The Company has consistently maintained a steady market leadership
                                and
                                currently holds considerable market share in both the general and life insurance business. SIC currently
                                holds <strong>8.71%</strong> shareholding in NTHC Limited.</p>
                        </div>
                    </div>
                </div>
                <div className="holders-card">
                    <div className="col-md-2 logo-image">
                        <div className="col-md-2 logo-image">
                            <figure>
                                <img src="/images/shareholders/acrecon_logo.png" alt="ACRECON Logo" width="512" height="512" />
                            </figure>
                        </div>
                    </div>
                    <div>
                        <div><h3>AcreCon Limited</h3></div>
                        <div>
                            <p>Acrecon Limited is a private Ghanaian company providing investment advisory and financial consultancy
                                services.
                                AcreCon Limited currently holds <strong>5.45%</strong> shareholding in NTHC Limited.</p>
                        </div>
                    </div>
                </div>
                <div className="holders-card">
                    <div className="col-md-2 logo-image">
                        <figure>
                            <img src="/images/shareholders/pf_logo.png" alt="PF Logo" width="512" height="512" />
                        </figure>
                    </div>
                    <div>
                        <div><h3>NTHC Provident Fund</h3></div>
                        <div>
                            <p>Staff of NTHC Group got the opportunity to own part of the Company in February 2001, through the staff
                                Provident Fund.
                                NTHC Provident Fund currently holds <strong>2.09%</strong> shareholding in NTHC Limited.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function Subsidiaries() {
    return (
        <div className='our-subsidiaries'>
            <h1>NTHC Group</h1>
            <p>NTHC Group is made up NTHC Limited and six(6) subsidiary companies.</p>
            <p><h3>Subsidiaries</h3></p>
            <ol type="1">
                <li>
                    <p><strong>•</strong> NTHC Asset Management Limited</p>
                </li>

                <li>
                    <p><strong>•</strong> NTHC Trustees Limited</p>
                </li>

                <li>
                    <p><strong>•</strong> NTHC Registrar Limited</p>
                </li>

                <li>
                    <p><strong>•</strong> NTHC Securities Limited</p>
                </li>

                <li>
                    <p><strong>•</strong> NTHC Commodities Limited</p>
                </li>

                <li>
                    <p><strong>•</strong> NTHC Properties Limited</p>
                </li>
            </ol>
        </div>
    )
}
function OperatingStructure() {
    return (
        <div className='operating-structure'>
            <h1> Operating Structure</h1>
            <p>NTHC Group comprises the holding Company NTHC Limited and six wholly-owned subsidiaries. The Group is
                managed at the Holding Company level by a Board of Directors with representation from Institutional
                Shareholders.
            </p>
            <p>
                Each Subsidiary is managed by a Board of Directors made up of independent, non executive and executive
                directors.
            </p>

            <p>
                <figure class="opt-img">
                    <img src="/images/nthc_group.jpg" alt="SIC Logo" width="1195" height="860" />
                </figure>
            </p>

        </div>
    )
}
function TrackRecord() {
    return (
        <div className='track-record'>
            <h1>Our Track Record</h1>
            <div class="track-record">
                <div class="track-record-box">
                    <img alt="" src="/images/tombstones/tomb_1.png" />
                </div>
                <div class="track-record-box">
                    <img alt="" src="/images/tombstones/tomb_3.png" />
                </div>
                <div class="track-record-box">
                    <img alt="" src="/images/tombstones/tomb_4.png" />
                </div>
                <div class="track-record-box">
                    <img alt="" src="/images/tombstones/tomb_7.png" />
                </div>
                <div class="track-record-box">
                    <img alt="" src="/images/tombstones/tomb_2.png" />
                </div>
            </div>
        </div>
    )
}

