/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ExecutiveChair from './ExecutiveChair';
import ExecutiveMembers from './ExecutiveMembers';
import { getCacheData, getFreshData, postData } from '../../utils/request';
import './ExecutivesStyles.css';

const EXECUTIVES_URL = '/api/management/executives';

function ExecutiveInfo({ useCache }) {
    const [executives, setExecutives] = useState([]);

    const getExecutiveData = async () => {
        setExecutives([]);

        if (useCache) {
            const cachedExecutiveData = getCacheData(EXECUTIVES_URL);
            if (cachedExecutiveData) {
                setExecutives(cachedExecutiveData)
            }
        }
        // const newExecutiveData = await getFreshData(EXECUTIVES_URL, useCache);
        const newExecutiveData = await postData(EXECUTIVES_URL, useCache, {
            company: "NTHC_Ltd",
        });
        setExecutives(newExecutiveData);
    };

    useEffect(() => {
        getExecutiveData();
    }, [])

    return (
        <div className="content">
            <h3 className="heading">Executive Team</h3>
            <div className="executives">
                {executives && executives.length > 0 && executives.map((exec, index) => (
                    <>
                        {exec.rank === 1 && (
                            <ExecutiveChair
                                key={index}
                                image={exec.image}
                                name={exec.name}
                                position={exec.position}
                                profile={exec.profile}
                            />
                        )}
                    </>
                ))}
                <div className="executive-members">
                    {executives && executives.length > 0 && executives.map((exec, index) => (
                        <>
                            {exec.rank !== 1 && (
                                <ExecutiveMembers
                                    key={index}
                                    image={exec.image}
                                    name={exec.name}
                                    position={exec.position}
                                    profile={exec.profile}
                                />
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ExecutiveInfo;