import './CompanyStyles.css';

function CompanyData(props) {
    return (
        <>
            <div className="sub-company-card">
                <h4>{props.heading}</h4>
                <p>{props.text1}</p><br />
                <p>{props.text2}</p><br />
                <p>{props.text3}</p><br />
                <p>{props.text4}</p><br />
                <p>{props.text5}</p><br />
                <p>{props.text6}</p><br />
                <p>{props.text7}</p><br />
                <p>{props.text8}</p>
            </div>
        </>
    )
}
export default CompanyData;