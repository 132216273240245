/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { getCacheData, getFreshData } from '../../utils/request';


const ARTICLES_URL = '/api/research/articles';

const Articles = ( { useCache }) => {
	const [articles, setArticles] = useState([]);
    
    const getArticles = async () => {
       setArticles([]);
       
        if (useCache) {
            const cachedArticles = getCacheData(ARTICLES_URL);
            if (cachedArticles) {
                setArticles(cachedArticles)
            }
        }
        const newArticles = await getFreshData(ARTICLES_URL, useCache);
        setArticles(newArticles);
    };

    useEffect(() => {
      getArticles();
    }, [])
        
  	return (    	
		<div className="content">
			<h3 className="heading">Articles</h3><br />
			<p>
			NTHC articles on Ghana’s money and capital markets during the specific period.
			</p><br />
			<div className="data-table">
				<table className="table table-borderless table-sm table-hover">
					<thead className="align">
						<tr>
							<th>File</th>
							<th>Description</th>
							<th>Edition</th>
							<th>Date</th>
							<th>Downloads</th>
						</tr>
					</thead>
					<tbody>
						{articles.length > 0 ? articles.map((item => {	
							return(
								<tr >
									<td style={{color:'brown'}}><i className="fa fa-file-pdf"></i></td>
									<td><a rel="noreferrer" target="_blank" href={`/uploads/research/${ item.file_name }`}>{item.description}</a> </td>
									<td>{`Edition ${item.edition}`}</td>
									<td>{item.date}</td>
									<td><a rel="noreferrer" target="_blank" download={"NTHC_Articles_" + item.date + ".pdf"} href={`/uploads/research/${ item.file_name }`}><i className="fa fa-cloud-download"></i>&nbsp;&nbsp; Download
									</a></td>
								</tr>
							)	
						})) : (	
								
									<div>
									<tr>
										<td colspan="5">
									<h3>Articles are yet to be uploaded</h3>
										</td>
									</tr>
								</div>
														)}
					</tbody>
				</table>
			</div>
		</div> 	
  	)
}

export default Articles